import React, { useEffect, useMemo, useState,useContext } from 'react';
import { Link,useNavigate,useParams } from 'react-router-dom';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../../../App.css';
import { MfctContext } from "../../../../context";
import Content from '../../../../components/Content';
import { getFilterRecord } from '../../../../service/localdata';
import VarContent from '../../../../components/VarContent';
import { addWorkingStone } from '../../../../service/milestone';
import { data } from '../../../../service/data';
import ContentTemplate from '../../../../components/display/ContentTemplate';

function AppContents({app,searchValue,sub_what}) {

const navigate = useNavigate()
const [contents,setContents]= useState([])
const { project, getProject,setAuthUser,authUser,    getGlobalData} = useContext (MfctContext);
const [schema,setSchema]=useState();
const [schemas, setSchemas] = useState([]);
const [publicContents,setPublicContents] = useState([])
const [showPublic,setShowPublic] = useState()

const [propSchema,setPropSchema]=useState([])
const [showTable,setShowTable]=useState(false)

const getContents = async()=>{
  await getGlobalData()
  var _schemas=  await  getFilterRecord((content)=> ((!content.app_id || parseInt(content.app_id)===parseInt(app.app_id) || content.is_public) && content.is_schema),"content")



  //  project.api("/get_schemas/:app_id",{args:{app_id:app.app_id} })

  var _contents =await  getFilterRecord((content)=>parseInt(content.app_id)===parseInt(app.app_id) && !content.is_schema,"content") 
  // await project.api("/app/:app_id/get_contents", { args:{app_id:app.app_id} })
  console.log( _contents)




  _schemas = _schemas.filter(t=>t.content.is_addable ).map(s=>({...s, nb :(s.app_id===app.app_id? 1 :0)+  _contents.filter(c=> c.schema_id ===s.content_id ).length      }));
  _schemas.sort((a,b)=> { 
  
    if( a.nb !== b.nb )return  a.nb > b.nb  ? -1: 1 
    
    return a.title < b.title ? -1 : 1});

  const tout ={title:"Tout",content_id:0}

  _schemas.unshift(tout)
setSchemas(_schemas);
setSchema(tout);
  setContents(_contents);
}

useEffect(()=>{
var s
if(sub_what){
s=schemas.find(t=>t.content_id+""===sub_what)
  setSchema( s)
}
    addWorkingStone( app, s?.title  ??"Contenus","app" )   

},[sub_what,schemas,app])

useEffect(()=>{
  const getProps = async()=>{
  if(schema?.content_id){
    var s = await project.api("/get_props/:schema_id",{args:{schema_id:schema.content_id}})
    for(let i in s){

         s[i].schema = await data.getSchema(s[i].is_prop ? s[i].prop_schema_id : s[i].porp_schema_id)
    }
    setPropSchema(s)
  }
}

getProps()
},[schema,project])

const createContent =async(content)=>{
  
  var _content =  await data.save_content({ ...content, content_id:null, app_id:app.app_id,is_public:false},true)
 if( _content)   navigate(`/content/${_content.content_id}`) ;
 }

const createFromContent =async(content)=>{
  
  var _content =  await project.api("/set_content",{body:{ ...content, content_id:null, app_id:app.app_id,is_public:false}})
 if( _content)await    getContents();
 }


const duplicateContent= async(content)=>{
  
 var _content =  await project.api("/duplicate_content/:content_id",{args:{content_id:content.content_id}})
if( _content)
      navigate(`/content/${_content.content_id}`);
}

const  deleteContent = async(content)=>{
  if (!window.confirm("Are you sure you want to delete '" + content.name+"'?")) return
   await project.api("/delete_content/:content_id",{args:{content_id:content.content_id}})
await    getContents();
}

useEffect(()=>{
  const getPublicContents = async()=>{
  

    var _contents =await  getFilterRecord((content)=> ( parseInt(content.schema_id)===parseInt(schema.content_id) && parseInt(content.app_id)!==parseInt(app.app_id))   ,"content") 
    // await project.api("/app/:app_id/get_contents", { args:{app_id:app.app_id} })
    console.log( _contents)
      setPublicContents(_contents);
  }
  

if(showPublic && schema?.content_id){
getPublicContents()
} 
else{
setPublicContents(null)
}

},[showPublic,schema,app])
useEffect(()=>{
if(project)
  getContents()
 
},[project])
 return (
    <div className="App w-full"> 
 
          <div className='block w-full text-black justify-start overflow-x-auto overflow-y-hidden'>
            
            <div  className='inline-flex  justify-start relative '>            {
              schemas.map((t,i)=><div key={i} 
              onClick={()=>{
                navigate( `/apps/${app.app_id}/Contents/${t.content_id}` )
                //setSchema(t)
                } } className={"m-2 h-16 w-24  shadow-lg border border-zinc-300 rounded-lg p-2 text-xs font-bold flex flex-col items-center justify-center " +  ( schema?.content_id===t.content_id? "":" opacity-50 ") + (t.nb>0 ?  "bg-orange-500 text-white" : (schema?.content_id===t.content_id ? "bg-zinc-300 sticky left-0 right-0 ":"bg-white"))}>
              <FontAwesomeIcon icon={t.content?.icone ? fa[t.content.icone]: fa.faQuestionCircle} />   {t.title}{t.content_id>0  &&  "s"}
              </div>)
            }
            </div>
           

            </div>
            <div className='w-full flex justify-end mt-2'>
            { schema?.content_id>0 &&  <>
<div onClick={ ()=>createContent({app_id:app.app_id, schema_id:schema?.content_id,title :(schema?.title  ?? "Content" ) +" " + Date.now()   })    } className=' text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6 appbutton' ><FontAwesomeIcon icon={fa.faPlusCircle} /> Créer {  schema?.content_id>0 ? schema.title :" contenu"  }</div>    
<div  onClick={()=>setShowPublic(true)} className=' text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6 appbutton' ><FontAwesomeIcon icon={fa.faPlusCircle} /> Créer {  schema?.content_id>0 ? schema.title :" contenu"  } depuis...</div> </>  }
     {schema?.content_id>0 &&
  <div onClick={()=>setShowTable(!showTable)} className={'mx-2 p-1 px-3 rounded-full mb-6 appbutton '+ (showTable ? " bg-orange-700 text-white" : "text-black  bg-white")}  ><FontAwesomeIcon icon={!showTable ? fa.faList : fa.faTable} /> {!showTable ? "Liste" :"Tableau" }</div>}
</div>
            <div  className='w-full  flex flex-wrap   rounded-xl ' >
{
  showTable && 
  <div className='text-sm w-full overflow-x-auto relative'>
  <table style={{minWidth:"100%"}}  >
    <thead>
      <tr  className='bg-orange-500 text-white p-2  sticky top-0' >
        <th  className='p-2'>Titre</th>
      {propSchema?.filter(p=>schema.content_id===p.porp_schema_id &&  !p.is_list && (p.schema.content.is_value   || p.schema.content.display_template_id )   && p.is_prop && !p.compute_id).map(p=><th className='p-2'>{p.name}</th>)}
        <th className='p-2 sticky right-0 bg-orange-500'>Actions</th>
      </tr>
    </thead>
    <tbody>
      {contents.filter(c =>( !schema?.content_id || c.schema_id===schema.content_id) && ( !searchValue || searchValue==="" || c.title?.toLowerCase().indexOf( searchValue.toLowerCase()   )>-1  ) ).map((content,i)=>
  <tr className="bg-white text-black p-2 border-t border-zinc-300" key={content.content_id}>
        <td>{content.title}</td>
      {propSchema?.filter(p=>schema.content_id===p.porp_schema_id &&  !p.is_list &&(p.schema.content.is_value   || p.schema.content.display_template_id ) && p.is_prop  && !p.compute_id ).map(p=><td>
        {
          p.schema.content.display_template_id ? 
          <ContentTemplate content={content.content[p.name]} schema={p.schema} template_id={p.schema.content.display_template_id} />
          :
       content.content[p.name]?.toString() }</td>)}
        <td className='p-2 sticky right-0 text-white bg-orange-500' >
          <Link to={`/content/${content.content_id}`}><FontAwesomeIcon className='cursor-pointer mx-2'     icon={fa.faEdit} /></Link>
          <FontAwesomeIcon className='cursor-pointer mx-2' icon={fa.faTrash} />
        </td>
      </tr> 
      )}
    </tbody>
  </table>
  </div>
}

   { !showTable &&  contents.filter(c =>( !schema?.content_id || c.schema_id===schema.content_id) && ( !searchValue || searchValue==="" || c.title?.toLowerCase().indexOf( searchValue.toLowerCase()   )>-1  ) ).map((content,i)=>
  <div className="w-full md:w-1/2 lg:w-1/3  xl:w-1/4 p-2 " key={content.content_id}>
     <Content className={"bg-white w-full text-black  border-none "} content={content }   deleteContent={deleteContent} duplicateContent={duplicateContent}    isEditable={true}   schema={schemas.find(s=>s.content_id===content.schema_id)   } />
</div>
   ) 
     }
     </div>   
   
     
    {publicContents &&  <div  className='w-full my-2 flex flex-wrap py-2  rounded-xl ' >
   {  publicContents.filter(c =>(   !searchValue || searchValue==="" || c.title?.toLowerCase().indexOf( searchValue.toLowerCase()   )>-1  ) ).map((content,i)=>
  <div className="w-full md:w-1/2 lg:w-1/3  xl:w-1/4 p-2 " key={content.content_id}>
  
     <Content className={"bg-white w-full text-black  border-none "} content={content }  duplicateContent={createFromContent}    isEditable={false}   schema={schema   } />
</div>
   ) 
     }
     </div>   
}
    </div>
  );
}

export default AppContents;