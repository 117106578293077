import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../../../App.css";
import { MfctContext } from "../../../../context";
import Content from "../../../../components/Content";
import { getFilterRecord } from "../../../../service/localdata";
import VarContent from "../../../../components/VarContent";
import { data } from "../../../../service/data";
import { forgetRessourceStone, setLoadedStone } from "../../../../service/milestone";

function Milestones({ searchValue }) {
  const navigate = useNavigate();
  const [contents, setContents] = useState([]);
  const { project, getProject, setAuthUser, authUser, getGlobalData } =
    useContext(MfctContext);
  const [schema, setSchema] = useState();
  const [schemas, setSchemas] = useState([]);

  const getContents = async () => {
    if (data.isStarted("milestone", "1")) return;
    //window.alert("getContents")
    await data.init("milestone", "1", null, null, { setContents });
    await getGlobalData();
    var _schemas = await data.getSchemas();

    //  project.api("/get_schemas/:app_id",{args:{app_id:app.app_id} })

    var _contents = await data.getContents(
      (content) => content.schema_id === 533 && !content.is_schema,true,true
    );
    // await project.api("/app/:app_id/get_contents", { args:{app_id:app.app_id} })

    for (let stone of _contents) {
      await data.loadCompleteContent(stone, true, _schemas);
    }
    //postStone( {...stone,Works : [...stone.Works,{user,Date: Date.now(),Action:"Work"}  ]} )

    console.log(_contents);

    setSchemas(_schemas);
    setContents(_contents);
  };

  const createContent = async () => {
    var _content = await project.api("/set_content", {
      body: {
        content_id: null,
        app_id: 78,
        schema_id: 533,
        is_public: false,
        content: {},
      },
    });
    if (_content) {
      await getContents();
      navigate(`/content/${_content.content_id}`);
    }
  };

  const duplicateContent = async (content) => {
    var _content = await project.api("/duplicate_content/:content_id", {
      args: { content_id: content.content_id },
    });
    if (_content) navigate(`/content/${_content.content_id}`);
  };

  const deleteContent = async (content) => {
    if (
      !window.confirm("Are you sure you want to delete '" + content.name + "'?")
    )
      return;
    await project.api("/delete_content/:content_id", {
      args: { content_id: content.content_id },
    });
    await getContents();
  };

  const Stones = useMemo(() => {
    var stones = contents.filter((c) => c.schema_id === 533);

    var _currentStone = stones.find((Stone) => {
      if (!Stone || !Stone.content.WorkingStone) return null;
      var timenow = Date.now();

      var lastAction = Stone.content.WorkingStone.filter(
        (w) =>
          w.User === "user_" + authUser?.user_id &&
          w.Date > timenow - 1000 * 60 * 30
      ).pop()?.Action;
      return lastAction && lastAction !== "Stop";
    });

    if (_currentStone) {
      localStorage.setItem("currentStone", _currentStone?.content_id);
      setLoadedStone(_currentStone);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      localStorage.removeItem("currentStone");
    }

    stones.sort((a, b) => {
      if (a.content_id === _currentStone?.content_id) return -1;

      if (b.content_id === _currentStone?.content_id) return 1;
      return a.updated_at > b.updated_at ? -1 : 1;
    });

    console.log("Refilter stones ", { stones });

    return stones;
  }, [contents, authUser]);

  useEffect(() => {
    if (project) {
      getContents();
    }
  }, [project]);

  const currentStone = useMemo(() => {

    return localStorage.getItem("currentStone");
  }, [Stones, authUser]);

  const currentWorkingStone = useMemo(() => {
   
    if (!Stones || Stones.length===0 || !currentStone) return;

   

    var obj = Stones.find((w) => w.content_id +""===""+ currentStone);
   // window.alert("search " +JSON.stringify( {obj}));
if(!obj ) return 

    var WorkingStone = obj.content.WorkingStone ?? [];

    var w = WorkingStone.filter(
      (w, i) =>
        w.Link &&
        i ===
          WorkingStone.findIndex(
            (w0) => JSON.stringify(w.Ressource) === JSON.stringify(w0.Ressource)
          )
    ).map((_w) => {
      var times = WorkingStone.filter(
        (w0) => JSON.stringify(_w.Ressource) === JSON.stringify(w0.Ressource)
      ).map((w0) => w0.Date);
      times.sort();
      _w.time = times.pop();
      return _w;
    });

    w.sort((a, b) => (a.time > b.time ? -1 : 1));

    return w;
  }, [Stones, currentStone]);

  useEffect(() => {
    return () => {
      //window.alert("finito")
      data.close("milestone", "1");
    };
  }, [project]);

  return (
    <div className="App w-full">
      <div className="w-full my-2 flex flex-col py-2 px-2 gap-2 rounded-xl ">
        {Stones.filter(
          (c) =>
            (!schema?.content_id || c.schema_id === schema.content_id) &&
            (!searchValue ||
              searchValue === "" ||
              c.title?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
        ).map((content, i) => (
          <div className={"w-full p-2 "} key={content.content_id}>
            <Content
              className={
                "w-full text-black  border-none " +
                (currentStone + "" === "" + content.content_id
                  ? " bg-green-100"
                  : " bg-white")
              }
              displayTemplate_id={ 541}
              content={content}
              deleteContent={deleteContent}
              duplicateContent={duplicateContent}
              isEditable={true}
              schema={schemas.find((s) => s.content_id === content.schema_id)}
            />

            {currentStone + "" === "" + content.content_id && currentWorkingStone &&
              currentWorkingStone.map((w, i) => (
                <div
                  className="w-full p-2  bg-green-100 rounded-xl text-black my-2  flex flex-col justify-between items-center"
                  key={i}
                >
                 <div className="w-full flex justify-between "> 
                  <span> <span  className="font-bold"  >{  w.Ressource.entity ??  w.Ressource.schema }</span>  - {w.Ressource.title ?? JSON.stringify(w.Ressource)}</span>

                  <span onClick={()=>forgetRessourceStone(w.Ressource)} className="appbuttton text-xs text-red-800">Oublier</span>
                  </div>

                  <div className="w-full pt-2 border-t border-black text-black my-2  flex flex-col  md:flex-row justify-start items-center gap-2">
                    {content.content.WorkingStone.filter(
                      (w0, i0) =>
                        w0.Link &&
                        JSON.stringify(w.Ressource) ===
                          JSON.stringify(w0.Ressource) &&
                        i0 ===
                          content.content.WorkingStone.findIndex(
                            (w1) =>
                              w1.Link === w0.Link &&
                              JSON.stringify(w0.Ressource) ===
                                JSON.stringify(w1.Ressource)
                          )
                    ).map((Wlink, j) => (
                      <Link className="w-1/2 md:w-auto" to={Wlink.Link} key={j}>
                        {" "}
                        <div
                          className={
                            "appbutton p-2 bg-green-500 rounded-full  text-white text-xs w-full "
                          }
                        >
                          {Wlink.Action}
                        </div>
                      </Link>
                    ))}{" "}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>

      <div
        onClick={createContent}
        className="sticky bottom-4 text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6 appbutton"
      >
        <FontAwesomeIcon icon={fa.faPlusCircle} /> Créer {" Milestone AppisYou"}
      </div>
    </div>
  );
}

export default Milestones;
